import { Field, FieldArray, Formik } from 'formik';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import Notification from '../../../../SharedComponents/Notification';
import RichTextEditor from '../../Pages/RichTextEditor';
import AddSliderImage from '../Slider/AddSliderImage';
import AddStatisticsImage from './AddStatisticsImage';
import { editStatisticItem } from './ApiStatistics';
import DeleteStatisticItem from './DeleteStatisticItem';

export default function StatisticItem(props) {
  const { t, i18n } = useTranslation();
  const { languages, pgStatisticsItem, languageId, pgStatisticsItemIndex, pgStatisticsItemsHelper, setFieldValue } =
    props;
  const queryClient = useQueryClient();
  const editStatisticItemMutation = useMutation(editStatisticItem, {
    onSuccess: async (data) => {
      if (data.isDone) {
        queryClient.invalidateQueries(['sections']);
      }
    },
  });
  const onSubmit = async (values) => {
    setFieldValue(`pgStatistics.pgStatisticsItems[${pgStatisticsItemIndex}]`, values);
    try {
      await editStatisticItemMutation.mutate(values);
    } catch (error) {
      console.log('error', error);
    }
  };
  return (
    <>
      {editStatisticItemMutation.isSuccess && <Notification message={t('Statistic Item Updated')} />}

      <Formik initialValues={pgStatisticsItem}>
        {({ values, setFieldValue }) => {
          return (
            <div className="border shadow mt-4 p-4 rounded">
              {/* Submit Form */}
              <div className="h-52 focus:outline-none group rounded border-dashed border-2 mb-2 relative">
                {(values.imageIcon && (
                  <>
                    <div className="flex items-center justify-center h-full p-2 bg-gray-100">
                      <img
                        className="w-full h-full object-contain"
                        alt="img"
                        src={`${process.env.REACT_APP_IMAGE_PATH}${values.imageIcon}`}
                      />{' '}
                    </div>
                    <div className="absolute group-hover:opacity-100 opacity-0 inset-0 flex items-center justify-center">
                      <AddStatisticsImage edit={true} setField={setFieldValue} fieldName="imageIcon" />
                    </div>
                  </>
                )) || (
                  <div className="absolute inset-0 flex items-center justify-center">
                    <AddStatisticsImage fieldName="imageIcon" setField={setFieldValue} />
                  </div>
                )}
              </div>

              <FieldArray
                name="pgStatisticsItemLngs"
                render={(arrayHelpers) => (
                  <>
                    {arrayHelpers.form.values.pgStatisticsItemLngs.map((e, index) => {
                      return (
                        <Fragment key={index}>
                          {languageId === e.languageId && (
                            <>
                              <label>
                                <Field
                                  className="border rounded  w-full  p-2 mb-2"
                                  type="text"
                                  placeholder={t('Add Title')}
                                  name={`pgStatisticsItemLngs[${index}].title`}
                                />
                              </label>
                              <label>
                                <div className="mb-2">
                                  <RichTextEditor
                                    fieldName={`pgStatisticsItemLngs[${index}].measureUnit`}
                                    fieldValue={values.pgStatisticsItemLngs[index].measureUnit}
                                    setFieldValue={setFieldValue}
                                  />
                                </div>
                              </label>
                            </>
                          )}
                        </Fragment>
                      );
                    })}
                  </>
                )}
              />
              <Field
                type="number"
                name="number"
                className="border rounded  w-full  p-2 mb-2"
                placeholder={t('Add Number')}
              />
              <Field
                type="number"
                name="measureUnit"
                className="border rounded  w-full  p-2 mb-2"
                placeholder={t('Add Measure Unit')}
              />
              <Field
                type="number"
                name="orderNum"
                className="border rounded  w-full  p-2 mb-2"
                placeholder={t('Add Order Number')}
              />
              <div className={`flex justify-end space-x-2 py-4 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}>
                <button
                  type="button"
                  onClick={() => {
                    onSubmit(values);
                  }}
                  className="bg-lightgreen py-2 px-2 flex justify-center text-center h-full text-sm font-bold text-white items-center hover:bg-lightgreenhover text-centerhover shadow  rounded transition duration-300 ease-in-out"
                >
                  {t('Update Item')}
                </button>
                <DeleteStatisticItem
                  id={values.id}
                  pgStatisticsItemsHelper={pgStatisticsItemsHelper}
                  pgStatisticsItemIndex={pgStatisticsItemIndex}
                />
              </div>
            </div>
          );
        }}
      </Formik>
    </>
  );
}
