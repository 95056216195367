import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import Calender from '../../../SharedComponents/Svg/Calender';
import Eye from '../../../SharedComponents/Svg/Eye';
import PecnilAlt from '../../../SharedComponents/Svg/PencilAlt';
import Trash from '../../../SharedComponents/Svg/Trash';
import { deleteAsset } from '../ApiAssets';
import EditAsset from './EditAsset';
import ViewAsset from './ViewAsset';

export default function AssetsStyleList(props) {
  const { asset, languageId, index } = props;
  const { fileUrl, id } = asset;
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();
  const { t, i18n } = useTranslation();
  const deleteButton = useMutation(deleteAsset, {
    onSuccess: () => {
      queryClient.invalidateQueries(['assetFolder']);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    },
  });

  const onDelete = async (e) => {
    setLoading(true);
    try {
      await deleteButton.mutate({ id });
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <>
      <div key={asset.id} className="py-4">
        <div className="flex justify-between items-center">
          <div className={`flex items-start space-x-4 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}>
            <img
              style={{ maxWidth: 300, maxHeight: 150 }}
              alt="img"
              src={`${process.env.REACT_APP_IMAGE_PATH}${fileUrl}`}
            />
            <div className=" ">
              {asset.astAssetLngs.map((e) => {
                return (
                  <>
                    {languageId === e.languageId && (
                      <div>
                        <b className="text-xl block mb-1">{e.title}</b>
                        <small className="flex items-center text-gray-400  space-x-1 text-xs mb-2">
                          <Calender className="w-3 h-3" />
                          <span>15/12/2020 02.30 PM</span>
                        </small>
                        <span className="text-sm text-gray-500 block">{e.alt}</span>
                        <span className="text-sm text-gray-500 block">{e.description}</span>
                        {/* <div className='my-2 block flex space-x-2'>
                          <span className='text-xs text-gray-500 border rounded p-1 border-gray-400  inline-block'>
                            Mission,page
                          </span>
                          <span className='text-xs text-gray-500 border rounded p-1 border-gray-400  inline-block'>
                            Title news name, News
                          </span>
                        </div> */}
                        <span className="text-sm text-gray-400 block">Uploaded By: Mahmoud</span>
                      </div>
                    )}
                  </>
                );
              })}
            </div>
          </div>
          <div className="flex flex-col space-y-1">
            <EditAsset asset={asset} withText={true} />
            <ViewAsset withText={true} image={`${process.env.REACT_APP_IMAGE_PATH}${fileUrl}`} />

            <button
              onClick={onDelete}
              className={`bg-pink hover:bg-pinkhover flex items-center ${
                i18n.language === 'en' ? '' : 'space-x-reverse'
              } space-x-2 text-xs transition duration-500 text-white rounded p-1`}
            >
              <Trash className="w-4 h-4" /> <span>{t('Delete')}</span>
            </button>
          </div>
        </div>
      </div>{' '}
    </>
  );
}
