import { useQuery } from 'react-query';
import AddUser from './AddUser';
import { getLanguages, getRoles, getUsers } from './ApiUser';
import { useState, useEffect } from 'react';
import EditUser from './EditUser';
import { useParams } from 'react-router-dom';
import Loading from '../../../SharedComponents/Loading';
import ToggleUserStatus from './ToggleUserStatus';
import { DateTime } from 'luxon';
import BreadCrumbs from '../../../SharedComponents/BreadCrumbs';
import { useTranslation } from 'react-i18next';
import TablePagination from '../../../SharedComponents/TablePagination';
import TableSearch from '../../../SharedComponents/TableSearch';
import LanguageList from '../../../SharedComponents/LanguageList';

export default function Users() {
  // to control how many rows to show in table
  const [entries, setEntries] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [languageId, setLanguageId] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchLanguage, setSearchlanguage] = useState(0);
  const onSearch = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
    setSearchlanguage(languageId);
  };
  // Query Data To Fetch Languages
  const languages = useQuery('languages', getLanguages, {
    staleTime: 360000000,
  });
  useEffect(() => {
    setLanguageId(languages.data?.data.sort((a, b) => (a.orderNum > b.orderNum ? 1 : -1))[0].id);
  }, [languages.data]);

  let initialState = {
    pageNumber: pageNumber,
    pageSize: entries,
    isPaging: true,
    langId: 0,
    isSearch: searchTerm ? true : false,
    searchFilter: {
      langId: searchLanguage,
      term: searchTerm,
    },
  };

  const parms = useParams();
  const { role } = parms;
  const roles = useQuery(['roles'], getRoles);

  const { data, isLoading, isError } = useQuery(['users', initialState], getUsers, {
    staleTime: 2000,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    onSuccess: (data) => {
      pagination(data);
    },
  });
  const { t, i18n } = useTranslation();

  const tabltHeader = [
    { title: t('name') },
    { title: t('email') },
    { title: t('phone') },
    { title: t('created') },
    { title: t('lastlogin') },
    { title: t('state') },
    { title: t('Role') },
    { title: t('options') },
  ];
  const [paginationCount, setPaginationCount] = useState([]);
  function pagination(data) {
    let paginationCount = [];
    for (var i = 1; i <= data.paginationResult.numberOfPages; i++) {
      paginationCount.push(i);
    }
    setPaginationCount(paginationCount);
  }

  // to control how many rows to show in table
  const onChangeEntries = (e) => {
    setEntries(e.target.value);
  };

  return (
    <>
      <header className="flex justify-between">
        <BreadCrumbs to="/users" name={t('Users')} />
        <div>
          <AddUser />
        </div>
      </header>
      <div className="my-6">
        <h2 className="text-2xl font-bold">{t('Users')}</h2>
      </div>
      {/* Table */}
      <div className="bg-white rounded border shadow-md">
        <div className="flex justify-between relative items-center px-6 py-6">
          <TableSearch onChangeEntries={onChangeEntries} entries={entries} onSearch={onSearch} />
          <div className="flex transform  relative z-10 ">
            {data && languages && (
              <LanguageList languages={languages?.data?.data} languageId={languageId} setLanguageId={setLanguageId} />
            )}
          </div>
          <hr className="border w-full absolute top-0 left-0 z-0 mt-16" />
        </div>
        {isLoading && (
          <div className="relative bg-white h-72">
            <Loading />
          </div>
        )}
        {isError && 'loading'}
        {data && (
          <div className="flex flex-col  overflow-hidden ">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className=" overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y-2 divide-gray-200">
                    <thead className=" text-sm font-bold text-black">
                      <tr>
                        {tabltHeader.map((e) => {
                          return (
                            <th
                              key={e.title}
                              scope="col"
                              className={`px-6 py-3 ${
                                i18n.language === 'en' ? 'text-left' : 'text-right'
                              }    uppercase `}
                            >
                              {e.title}
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200 relative text-base text-darkgray font-normal">
                      {data &&
                        data.data &&
                        data?.data
                          .filter((e) => (role ? e.role.toLowerCase() === role : e))
                          .map((user) => {
                            return (
                              <tr key={user.id} className="">
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <div className=" ">{user.userName}</div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <div className=" ">{user.email}</div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <div className=" ">{user.phone}</div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <div className=" ">{DateTime.fromISO(user.creationDate).toLocaleString('en-GB')}</div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <div className=" ">
                                    {DateTime.fromISO(user.lastLoginDate).toLocaleString('en-GB')}
                                  </div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <ToggleUserStatus id={user.id} userStatus={user.userStatus} />
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  {user.roles.map((role) => {
                                    return (
                                      <div
                                        key={role}
                                        className="bg-darkblue text-white inline-block text-xs px-2 py-1 rounded-full font-bold"
                                      >
                                        {role}
                                      </div>
                                    );
                                  })}
                                  {user.roles.length === 0 && (
                                    <div className="bg-darkblue text-white inline-block text-xs px-2 py-1 rounded-full font-bold">
                                      Not Assigned
                                    </div>
                                  )}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap flex items-center space-x-2">
                                  <EditUser roles={roles} user={user} />
                                </td>
                              </tr>
                            );
                          })}
                    </tbody>
                  </table>

                  <TablePagination
                    data={data}
                    paginationCount={paginationCount}
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
