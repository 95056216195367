import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import ChevronLeft from '../../../SharedComponents/Svg/ChevronLeft';
import { getFolder, getFolders } from '../../Assets/ApiAssets';
import folder from '../../../assets/img/folder.svg';
import { DateTime } from 'luxon';
import { useParams } from 'react-router';
import { addGalleryAlbumItemList } from '../../Gallery/ApiGallery';
import LanguageList from '../../../SharedComponents/LanguageList';
import Eye from '../../../SharedComponents/Svg/Eye';
export default function SelectImage(props) {
  const { setFieldValue, imageId, imageUrl, imageIdValue, imageUrlValue } = props;
  let [isOpen, setIsOpen] = useState(false);
  const [languageId, setLanguageId] = useState(process.env.REACT_APP_DEFAULT_LANGUAGE);
  const { albumId } = useParams();

  const { t, i18n } = useTranslation();
  const [activeTab, setActiveTab] = useState(1);

  const [assetCollection, setAssetCollection] = useState({
    id: imageIdValue,
    url: imageUrlValue,
  });
  const queryClient = useQueryClient();

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }
  const languages = [{ id: 1, id: 2 }];
  const [pageNumber, setPageNumber] = useState(1);
  const [entries, setEntries] = useState(10);
  const [publicFolderId, setPublicFolderId] = useState(1);
  const glAlbumItemLngs = languages.map((language) => ({
    languageId: language.id,
    title: '',
    description: '',
    btnNextText: '',
    btnPreviousText: '',
    btnCloseText: '',
    keywords: '',
  }));
  // initial state for APi call
  let initialState = {
    typeId: parseInt(process.env.REACT_APP_DEFAULT_IMAGE_ASSETS),
    paginationFilter: {
      pageNumber: pageNumber,
      pageSize: entries,
      isPaging: false,
      langId: 0,
    },
  };

  // Query to load Data
  const { data, isDone, isLoading, isError } = useQuery(['assetFolders', initialState], getFolders, {
    staleTime: 1000,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setPublicFolderId(data?.data[0]?.id !== undefined ? data?.data[0]?.id : 1);
    },
  });

  // Query to load publicFolder Data
  const publicFolder = useQuery(['assetFolder', publicFolderId], getFolder, {
    staleTime: 1000,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const addGalleryAlbumItemListMutation = useMutation(addGalleryAlbumItemList, {
    onSuccess: () => {
      closeModal();
      queryClient.invalidateQueries('galleryAlbum');
    },
  });
  const addAllAssets = (e) => {
    setFieldValue(imageId, assetCollection.id);
    setFieldValue(imageUrl, assetCollection.url);
    closeModal();
  };

  return (
    <>
      <div className="flex items-center justify-center space-x-2">
        {imageUrlValue && (
          <div className="bg-green-500 text-white flex items-center justify-center p-1 rounded  ">
            <a target="_blank" href={imageUrlValue} rel="noreferrer">
              <Eye />
            </a>
          </div>
        )}
        <button
          type="button"
          onClick={openModal}
          className="px-4 py-2 text-sm font-medium text-white bg-gray-700  rounded-md  focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
        >
          {imageUrlValue ? t('Replace Image') : t('Select Image')}
        </button>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-50 overflow-y-auto" onClose={closeModal}>
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed bg-black opacity-70 inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            {/* <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span> */}
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                className="inline-block w-full overflow-y-scroll p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl"
                style={{
                  height: '90vh',
                }}
              >
                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                  {t('Select Video')}
                </Dialog.Title>
                {/* <LanguageList /> */}
                <div className="mt-2 ">
                  {publicFolderId !== data?.data[0]?.id && (
                    <div className="flex justify-end">
                      <button
                        onClick={() => {
                          setPublicFolderId(data?.data[0]?.id);
                        }}
                        className={`flex focus:outline-none items-center ${
                          i18n.language === 'en' ? '' : 'flex-row-reverse  '
                        } justify-start border pr-4  bg-white hover:bg-gray-100 rounded text-gray-800 transition duration-500`}
                      >
                        <ChevronLeft className="w-5 h-5" /> <span>{t('GO BACK')}</span>
                      </button>
                    </div>
                  )}
                  <button
                    className="bg-lightgreen space-x-1  text-sm font-bold text-white flex items-center hover:bg-lightgreenhover shadow  px-4 py-2 rounded transition duration-300 ease-in-out"
                    type="button"
                    onClick={addAllAssets}
                  >
                    {'Save Selection'}
                  </button>

                  <div className="flex items-center grid grid-cols-3 lg:grid-cols-5 gap-8 mt-4 mb-8 ">
                    {data?.data[0]?.id === publicFolderId &&
                      data?.data?.slice(1).map((e) => {
                        return (
                          <FileHolder
                            setPublicFolderId={setPublicFolderId}
                            folderData={e}
                            name={e.name}
                            key={e.id}
                            id={e.id}
                            typeId={activeTab}
                            itemCount={e.itemCount}
                          />
                        );
                      })}
                    {publicFolder &&
                      publicFolder.data &&
                      publicFolder.data.data &&
                      publicFolder?.data.data.astAssets
                        .filter((e) => e.typeId === activeTab)
                        .map((e) => {
                          return (
                            <ImageHolder
                              assetCollection={assetCollection}
                              setAssetCollection={setAssetCollection}
                              languageId={languageId}
                              asset={e}
                              key={e.id}
                            />
                          );
                        })}
                  </div>
                </div>

                <div className="mt-4">
                  <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={closeModal}
                  >
                    Got it, thanks!
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

const FileHolder = (props) => {
  const { name, itemCount, id, setPublicFolderId, folderData, typeId } = props;
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      <div className="relative bg-white  z-5 rounded shadow-md border py-4 px-2">
        <div
          onClick={() => {
            setPublicFolderId(id);
          }}
          className=""
        >
          <div className="text-center">
            <img className="inline-block rounded" alt={folder} src={folder} />
            <b className="block mt-1 mb-2">{name}</b>
            <span className="block text-sm text-gray-400">
              {itemCount} {t('Items')}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

const ImageHolder = (props) => {
  const { asset, languageId, assetCollection, setAssetCollection } = props;
  // const [isOpen, setIsOpen] = useState(false);
  const onChange = () => {
    // console.log('changed', props);
    // if (assetCollection.includes(asset.id) === false)
    //   setAssetCollection([...assetCollection, asset.id]);
    // if (assetCollection.includes(asset.id) === true)
    //   setAssetCollection(assetCollection.filter((e) => e !== asset.id));
    // console.log('asset', asset);

    setAssetCollection({
      id: asset.id,
      url: asset.fileUrl,
    });
  };
  const { t } = useTranslation();
  return (
    <>
      <div className="relative bg-white  z-0 p-1 rounded shadow-md border " style={{ minHeight: 200 }}>
        <input
          className="absolute top-0 z-10 left-0 border m-4 h-4 w-4 "
          type="checkbox"
          checked={assetCollection.id === asset.id}
          onChange={onChange}
        ></input>

        <div className="w-full">
          <div className="text-center ">
            <div>
              <div className="relative flex justify-center bg-white">
                <img
                  className="inline-block rounded object-cover  w-full"
                  alt={`alt youtube`}
                  style={{ maxWidth: 200, maxHeight: 200 }}
                  src={`${process.env.REACT_APP_IMAGE_PATH}${asset.fileUrl}`}
                />
              </div>
              {/* <div className="relative flex justify-center bg-white">
                <img
                  className="inline-block rounded object-cover h-20 w-full"
                  alt={`alt`}
                  style={{ maxWidth: 78, maxHeight: 78 }}
                  src={`${process.env.REACT_APP_IMAGE_PATH}${asset.fileUrl}`}
                />
              </div> */}
              {asset.astAssetLngs.map((e) => {
                return (
                  <Fragment key={e.id}>
                    {languageId === e.languageId && (
                      <div key={e.id}>
                        <b className="block mt-1 mb-2">{e.title}</b>
                        <span className="block text-sm text-white">{t('am Hidden')}</span>
                      </div>
                    )}
                  </Fragment>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const getYoutubeThumbnail = (imageUrl) => {
  const regEx = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
  const url = imageUrl?.match(regEx);
  return url ? url[1] : '';
};
